/*#region paddingTop*/

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-35 {
    padding-top: 35px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-100 {
    padding-top: 100px;
}

/*#endregion paddingTop*/

/*#region paddingBottom*/
.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-35 {
    padding-bottom: 35px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}
/*#endregion paddingBottom*/

/*#region paddingY*/

.padding-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.padding-y-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.padding-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.padding-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*#endregion paddingY*/

/*#region paddingX*/

.padding-x-10p {
    padding: 0 10%;
}

.padding-x-10 {
    padding: 0 10px;
}

.padding-x-15 {
    padding: 0 15px;
}

.padding-x-20 {
    padding: 0 20px;
}



/*#endregion paddingX*/

/*#region marginTop*/

.margin-top-0 {
    margin-top: 0;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}


.margin-top-25 {
    margin-top: 25px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

/*#endregion marginTop*/

/*#region marginBottom*/
.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}
/*#endregion marginBottom*/

/*#region marginY*/

.margin-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.margin-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}


.margin-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.margin-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.margin-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.margin-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.margin-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.margin-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

/*#endregion marginY*/

.white {
    color: #FFF;
}

.black {
    color: #000;
}

.container-bg-white {
    background-color: #fff;
}

.uppercase {
    text-transform: uppercase;
}

.relative {
    position: relative;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

img.HoverBounce,
.HoverBounce img {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    display: block;
    overflow: hidden;
}

    img.HoverBounce :hover,
    .HoverBounce img :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

a.HoverBounce {
    display: block;
}

    a.HoverBounce img {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
        transition: -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        display: block;
        overflow: hidden;
    }

    a.HoverBounce :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

/*#region fonts*/
.OpenSans,
.BodyFont {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.OpenSansBold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700 !important;
}
.PTSansNarrow {
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 400 !important;
}
.PTSansNarrowBold {
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700 !important;
}
@font-face {
  font-family: 'Badaboom';
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/badaboom.eot');
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/badaboom.eot?#iefix') format('embedded-opentype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/badaboom.woff2') format('woff2'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/badaboom.woff') format('woff'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/badaboom.ttf') format('truetype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/badaboom.svg#badaboom') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.Badaboom {
  font-family: 'Badaboom';
  font-weight: normal;
}
html {
  font-size: 16px;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.textContent {
  font-size: 1rem;
}
.textContent h1,
.lower-content h2 {
  font-family: 'Badaboom';
  font-weight: normal;
  font-size: 2.5rem;
  color: #c8002d;
  margin-bottom: 25px;
}
@media (min-width: 768px) {
  .textContent h1,
  .lower-content h2 {
    font-size: 3rem;
  }
}
@media (max-width: 767px) {
  .textContent h1,
  .lower-content h2 {
    margin-top: 0;
    text-align: center;
  }
}
.section-title {
  font-family: 'Badaboom';
  font-weight: normal;
  font-size: 2.5rem;
}
@media (min-width: 768px) {
  .section-title {
    font-size: 3rem;
  }
}
/*#endregion fonts*/
form {
  max-width: 100vw;
  overflow: hidden;
}
.outer-wrapper {
  max-width: 100vw;
  position: relative;
}
@media (min-width: 992px) {
  .outer-wrapper--homepage .main-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.outer-wrapper--homepage .main-content__main {
  padding-top: 0;
}
@media (min-width: 992px) {
  .outer-wrapper--homepage .main-content__main {
    width: 60%;
  }
}
.outer-wrapper--homepage .main-content__right {
  display: none;
}
@media (min-width: 992px) {
  .outer-wrapper--homepage .main-content__right {
    display: block;
    position: relative;
    width: 40%;
  }
}
.outer-wrapper--homepage .main-content__right-image {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.outer-wrapper--homepage .page-listings__wrapper {
  padding: 50px 0;
}
.container {
  position: relative;
}
@media (max-width: 767px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 1230px) {
  .container {
    width: 1200px;
  }
}
@media (min-width: 1330px) {
  .container {
    width: 1300px;
  }
}
/*#region topLinksMobile*/
#TopLinksMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #2c5ca8;
  color: white;
  z-index: 20;
  padding: 0;
  border-bottom: 1px solid white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
#TopLinksMobile .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: none !important;
  }
}
#TopLinksMobile #NavigationToggleText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 14px 63px;
  text-align: right;
}
#TopLinksMobile .navbar-toggle .icon-bar {
  background: white;
}
#TopLinksMobile .row {
  padding-top: 10px;
}
#TopLinksMobile .input-group {
  margin-bottom: 0;
}
#MobileHomeLink {
  position: fixed;
  top: 10px;
  z-index: 10;
  background: transparent none repeat scroll 0 0;
  color: white;
  left: 10px;
  border-radius: 50%;
  border: 2px solid white;
}
/*#endregion topLinksMobile*/
.header {
  background: #000;
  padding: 80px 0 40px;
  text-align: center;
}
@media (min-width: 768px) {
  .header {
    padding: 0;
  }
}
.header__container {
  position: relative;
}
@media (min-width: 768px) {
  .header__container {
    padding: 10px 0;
    min-height: 250px;
  }
}
@media (min-width: 768px) {
  .header__logo {
    position: absolute;
    left: 5px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 25%;
  }
}
.header__phone,
.header__phone-2,
.header__call {
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700 !important;
  color: #fff;
}
@media (min-width: 768px) {
  .header__phone,
  .header__phone-2,
  .header__call {
    position: absolute;
    right: 5px;
  }
}
.header__phone,
.header__phone-2 {
  font-size: 2.99rem;
}
.header__phone:hover,
.header__phone-2:hover {
  color: #fff;
}
@media (min-width: 768px) {
  .header__phone {
    bottom: 75px;
  }
}
.header__call {
  color: #e6007e;
  font-size: 1.625rem;
}
@media (max-width: 767px) {
  .header__call {
    margin-top: 20px;
  }
}
@media (min-width: 768px) {
  .header__call {
    bottom: 141px;
  }
}
.header__social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 767px) {
  .header__social-links {
    margin-top: 20px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media (min-width: 768px) {
  .header__social-links {
    position: absolute;
    right: 5px;
    bottom: 30px;
  }
}
.header__social-link {
  width: 40px;
  margin: 0 5px;
}
.toplinks__wrapper {
  background: #f8c722;
}
.toplinks__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
}
.toplinks__li {
  position: relative;
  list-style: none;
  text-align: center;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.toplinks__li .dropdown-toggle:focus {
  text-decoration: none;
}
.toplinks__link {
  position: relative;
  display: block;
  padding: 15px 5px;
  color: #000;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 1.3rem;
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700 !important;
  text-transform: uppercase;
}
.toplinks__link:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-top: 8px solid #000;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  opacity: 0;
}
.toplinks__link:hover,
.toplinks__link.active,
.toplinks__link:focus {
  color: #000;
}
.toplinks__link:hover:before,
.toplinks__link.active:before,
.toplinks__link:focus:before {
  opacity: 1;
}
.toplinks__dropdown {
  padding: 0;
  margin: 0;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-align: center;
  background: #f8c722;
  border-radius: 0;
}
.toplinks__dropdown-li {
  display: block;
}
.toplinks__dropdown-link {
  position: relative;
  padding: 5px 10px !important;
  font-size: 1.3rem;
  color: #000 !important;
  text-transform: uppercase;
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700 !important;
  -webkit-transition: color 0.3s, background-color 0.3s;
  transition: color 0.3s, background-color 0.3s;
}
.toplinks__dropdown-link:hover,
.toplinks__dropdown-link:focus,
.toplinks__dropdown-link.active {
  background-color: #000 !important;
  color: #ffc600 !important;
}
.banner #CarouselContainer {
  padding-top: 32.55%;
  overflow: hidden;
}
.banner #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.banner #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.banner #CarouselContainer .carousel-inner .item a img,
.banner #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
.banner__text {
  background: #000;
  padding: 25px 10px;
  text-align: center;
  font-family: 'Badaboom';
  font-weight: normal;
  font-size: 2.5rem;
}
@media (min-width: 768px) {
  .banner__text {
    font-size: 3rem;
  }
}
@media (min-width: 768px) {
  .banner__text {
    padding: 25px 0;
  }
}
.banner__text .yellow {
  color: #f8c722;
}
.banner__text .pink {
  color: #e6007e;
}
.banner__text .blue {
  color: #009fe3;
}
.featured-categories {
  margin: 50px auto 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.featured-categories__gallery {
  width: 49%;
  position: relative;
  background-size: 100% 100%;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .featured-categories__gallery {
    width: 49.5%;
  }
}
@media (max-width: 525px) {
  .featured-categories__gallery {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .featured-categories__gallery {
    width: 49.5%;
  }
}
@media (min-width: 992px) {
  .featured-categories__gallery {
    width: 24.5%;
    margin-bottom: 0;
  }
}
.featured-categories__gallery-inner {
  padding: 6% 9.67%;
  padding-bottom: 2%;
}
.featured-categories__image-panel {
  overflow: hidden;
  position: relative;
}
.featured-categories__image-panel:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 78.01%;
}
.featured-categories__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.featured-categories__title {
  color: #fff;
  padding: 0 10px;
  font-size: 1.3rem;
  text-align: center;
  text-shadow: 0 1px rgba(0, 0, 0, 0.3);
  pointer-events: none;
  margin-top: 5%;
}
.featured-categories .carousel {
  margin: 0 auto;
}
@media (max-width: 767px) {
  .featured-categories .carousel {
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) {
  .main-content__main {
    padding-top: 30px;
  }
}
.home-lower-content {
  background: #000;
  padding: 50px 0 70px;
}
@media (max-width: 767px) {
  .home-lower-content {
    text-align: center;
  }
}
.home-lower-content__main {
  color: #fff;
  font-size: 1rem;
}
@media (min-width: 992px) {
  .home-lower-content__main {
    padding-right: 20px;
  }
}
.home-lower-content__main h1,
.home-lower-content__main h2 {
  font-family: 'Badaboom';
  font-weight: normal;
  font-size: 2.5rem;
  color: #f8c722;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .home-lower-content__main h1,
  .home-lower-content__main h2 {
    font-size: 3rem;
  }
}
.home-lower-content__main h1:first-child,
.home-lower-content__main h2:first-child {
  margin-top: 0;
}
.home-lower-content__right {
  margin-top: 40px;
}
@media (min-width: 992px) {
  .home-lower-content__right {
    margin-top: 0;
  }
}
.products-intro {
  background: #f8c722;
  color: #100a09;
  padding: 20px 0 50px;
  font-size: 1rem;
  text-align: center;
}
.products-intro h1,
.products-intro h2 {
  font-family: 'Badaboom';
  font-weight: normal;
  font-size: 2.5rem;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .products-intro h1,
  .products-intro h2 {
    font-size: 3rem;
  }
}
.page-listings {
  margin-left: 0;
  margin-right: 0;
}
.page-listings__wrapper {
  padding: 0 0 40px;
}
@media (max-width: 420px) {
  .page-listings > div {
    width: 100%;
  }
}
.castlePanel {
  width: 100%;
  margin: 30px 0 0;
  padding: 10px 10px 20px;
  background: #fff;
  border: 1px solid #c7c7c7;
  position: relative;
  text-align: center;
}
.castlePanel:before {
  content: "";
  width: 15px;
  height: 50%;
  background: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 5px #6e6e6e;
          box-shadow: 0 0 5px #6e6e6e;
  position: absolute;
  top: 8px;
  left: -7px;
  -webkit-transform-origin: center top;
          transform-origin: center top;
  -webkit-transform: rotate(-3deg);
          transform: rotate(-3deg);
  -webkit-filter: blur(3px);
          filter: blur(3px);
  -webkit-transition: all 0.45s ease-out;
  transition: all 0.45s ease-out;
  z-index: -1;
}
.castlePanel:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background: #fff;
  z-index: -1;
}
.castlePanel:hover:before {
  opacity: 0;
  left: 0px;
  top: 0px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-box-shadow: 0 0 0 #6e6e6e;
          box-shadow: 0 0 0 #6e6e6e;
  -webkit-filter: blur(0px);
          filter: blur(0px);
}
.castlePanel .SingleImagePanel {
  height: auto;
}
.castlePanel .castleTitlePanel {
  margin: 10px 0 0;
  padding: 0 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.castlePanel .castleTitlePanel a {
  color: #303030;
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 400 !important;
  font-size: 1.4rem;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  padding: 0;
  margin-top: 10px;
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700 !important;
  color: #d10300;
  font-size: 1.4rem;
}
.castlePanel .castleCheckBook {
  margin-top: 10px;
  height: auto;
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 400 !important;
  background: #d10300;
  padding: 8px 15px;
  display: inline-block;
  font-size: 1.3rem;
}
.DetailsTitle h1,
.DetailsTitle h2,
.DetailsMainImage {
  background-color: #2c5ca8;
}
.BookButtons {
  text-align: center;
}
.BookButtons .BookButton,
.BookButtons .CheckButton {
  width: 180px;
  margin-left: auto;
  float: none;
  margin: 0 10px 10px;
}
.footer {
  background: #000;
  padding: 20px 0 50px;
  color: #a1a1a1;
}
@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .footer__flex-row-sm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media (min-width: 768px) {
  .footer__text-center-sm {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .footer__text-right-sm {
    text-align: right;
  }
}
.footer__links {
  text-align: center;
}
.footer__link {
  margin: 0 5px;
  color: #a1a1a1;
}
.footer__link:hover {
  color: #a1a1a1;
}
.footer #BCNLink {
  color: #a1a1a1;
}
.footer #BCNLink:hover {
  color: #a1a1a1;
}
/*#region Animations*/
/*#endregion Animations*/
