@import "../Assets/Functions.less";
@import (inline) "../Assets/Utilities.css";
@mobile: ~"(max-width: 767px)";
@tablet: ~"(min-width: 768px)";
@desktop: ~"(min-width: 1230px)";
@widescreen: ~"(min-width: 1330px)";
@mobileNavColor: #2c5ca8;
@castleDetailsColor: #2c5ca8;
@cloudUrl: "https://files.bookingonline.co.uk/image/upload/f_auto/themes/031/";

/*#region fonts*/
.OpenSans, .BodyFont {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
}

.OpenSansBold {
	font-family: 'Open Sans', sans-serif;
	font-weight: 700 !important;
}

.PTSansNarrow {
	font-family: 'PT Sans Narrow', sans-serif;
	font-weight: 400 !important;
}

.PTSansNarrowBold {
	font-family: 'PT Sans Narrow', sans-serif;
	font-weight: 700 !important;
}

.font-Badaboom;

.Badaboom {
	font-family: 'Badaboom';
	font-weight: normal;
}

html {
	font-size: 16px;
}

body {
	.BodyFont;
}

.nicefont {
}

.textContent {
	font-size: 1rem;
}

.textContent h1, .lower-content h2 {
	.section-title;
	color: #c8002d;
	margin-bottom: 25px;

	@media @mobile {
		margin-top: 0;
		text-align: center;
	}
}

.section-title {
	.Badaboom;
	font-size: 2.5rem;

	@media @sm {
		font-size: 3rem;
	}
}

h2, h3, h4 {
}
/*#endregion fonts*/
form {
	max-width: 100vw;
	overflow: hidden;
}

.outer-wrapper {
	max-width: 100vw;
	position: relative;

	&--homepage {
		.main-content {
			@media @md {
				display: flex;
			}

			&__main {
				padding-top: 0;

				@media @md {
					width: 60%;
				}
			}

			&__right {
				display: none;

				@media @md {
					display: block;
					position: relative;
					width: 40%;
				}
			}

			&__right-image {
				position: absolute;
				bottom: 0;
				width: 100%;
			}
		}

		.page-listings__wrapper {
			padding: 50px 0;
		}
	}
}

.container {
	position: relative;

	@media @mobile {
		width: 100%;
	}

	@media @tablet {
		width: 100%;
	}

	@media @desktop {
		width: 1200px;
	}

	@media @widescreen {
		width: 1300px;
	}
}
/*#region topLinksMobile*/
#TopLinksMobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: @mobileNavColor;
	color: white;
	z-index: 20;
	padding: 0;
	border-bottom: 1px solid white;
	.BodyFont();

	.navbar {
		margin-bottom: 0;
	}

	@media @tablet {
		display: none !important;
	}

	#NavigationToggleText {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 14px 63px;
		text-align: right;
	}

	.navbar-toggle .icon-bar {
		background: white;
	}

	.row {
		padding-top: 10px;
	}

	.input-group {
		margin-bottom: 0;
	}
}

#MobileHomeLink {
	position: fixed;
	top: 10px;
	z-index: 10;
	background: transparent none repeat scroll 0 0;
	color: white;
	left: 10px;
	border-radius: 50%;
	border: 2px solid white;
}
/*#endregion topLinksMobile*/
.header {
	background: #000;
	padding: 80px 0 40px;
	text-align: center;

	@media @tablet {
		padding: 0;
	}

	&__container {
		position: relative;

		@media @tablet {
			padding: 10px 0;
			min-height: 250px;
		}
	}

	&__logo {
		@media @tablet {
			position: absolute;
			left: 5px;
			top: 50%;
			transform: translateY(-50%);
			width: 25%;
		}
	}

	&__phone, &__phone-2, &__call {
		.PTSansNarrowBold;
		color: #fff;

		@media @tablet {
			position: absolute;
			right: 5px;
		}
	}

	&__phone, &__phone-2 {
		font-size: 2.99rem;

		&:hover {
			color: #fff;
		}
	}

	&__phone {
		@media @tablet {
			bottom: 75px;
		}
	}

	&__phone-2 {
	}

	&__call {
		color: #e6007e;
		font-size: 1.625rem;

		@media @mobile {
			margin-top: 20px;
		}

		@media @tablet {
			bottom: 141px;
		}
	}

	&__social-links {
		display: flex;

		@media @mobile {
			margin-top: 20px;
			justify-content: center;
		}

		@media @tablet {
			position: absolute;
			right: 5px;
			bottom: 30px;
		}
	}

	&__social-link {
		width: 40px;
		margin: 0 5px;
	}
}

.toplinks {
	&__wrapper {
		background: #f8c722;
	}

	&__nav {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 0;
		padding-left: 0;
	}

	&__li {
		position: relative;
		list-style: none;
		text-align: center;
		flex-grow: 1;

		.dropdown-toggle:focus {
			text-decoration: none;
		}
	}

	&__link {
		position: relative;
		display: block;
		padding: 15px 5px;
		color: #000;
		transition: all 0.3s;
		font-size: 1.3rem;
		.PTSansNarrowBold;
		text-transform: uppercase;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			border-top: 8px solid #000;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			transition: opacity 0.3s;
			opacity: 0;
		}

		&:hover, &.active, &:focus {
			color: #000;

			&:before {
				opacity: 1;
			}
		}
	}

	&__dropdown {
		padding: 0;
		margin: 0;
		border: none;
		box-shadow: none;
		text-align: center;
		background: #f8c722;
		border-radius: 0;
	}

	&__dropdown-li {
		display: block;
	}

	&__dropdown-link {
		position: relative;
		padding: 5px 10px !important;
		font-size: 1.3rem;
		color: #000 !important;
		text-transform: uppercase;
		.PTSansNarrowBold;
		transition: color 0.3s, background-color 0.3s;

		&:hover, &:focus, &.active {
			background-color: #000 !important;
			color: #ffc600 !important;
		}
	}
}

.banner {
	.BannerPanelSet(32.55%);

	&__text {
		background: #000;
		padding: 25px 10px;
		text-align: center;
		.section-title;

		@media @sm {
			padding: 25px 0;
		}

		.yellow {
			color: #f8c722;
		}

		.pink {
			color: #e6007e;
		}

		.blue {
			color: #009fe3;
		}
	}
}

.featured-categories {
	margin: 50px auto 15px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	&__gallery {
		width: 49%;
		position: relative;
		background-size: 100% 100%;
		margin-bottom: 15px;

		@media @mobile {
			width: 49.5%;
		}

		@media (max-width:525px) {
			width: 100%;
		}

		@media @sm {
			width: 49.5%;
		}

		@media @md {
			width: 24.5%;
			margin-bottom: 0;
		}
	}

	&__gallery-inner {
		padding: 6% 9.67%;
		padding-bottom: 2%;
	}

	&__image-panel {
		overflow: hidden;
		position: relative;

		&:before {
			content: "";
			display: block;
			width: 100%;
			padding-top: 78.01%;
		}
	}

	&__image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}

	&__title {
		//.Bjola;
		color: #fff;
		padding: 0 10px;
		font-size: 1.3rem;
		text-align: center;
		text-shadow: 0 1px rgba(0,0,0,0.3);
		pointer-events: none;
		margin-top: 5%;
	}

	.carousel {
		margin: 0 auto;

		@media @mobile {
			margin-bottom: 15px;
		}
	}
}

.main-content {
	&__main {
		@media @tablet {
			padding-top: 30px;
		}
	}
}

.home-lower-content {
	background: #000;
	padding: 50px 0 70px;

	@media @mobile {
		text-align: center;
	}

	&__main {
		color: #fff;
		font-size: 1rem;

		@media @md {
			padding-right: 20px;
		}

		h1, h2 {
			.section-title;
			color: #f8c722;
			margin-bottom: 20px;

			&:first-child {
				margin-top: 0;
			}
		}
	}

	&__right {
		margin-top: 40px;

		@media @md {
			margin-top: 0;
		}
	}
}

.products-intro {
	background: #f8c722;
	color: #100a09;
	padding: 20px 0 50px;
	font-size: 1rem;
	text-align: center;

	h1, h2 {
		.section-title;
		margin-bottom: 20px;
	}
}

.page-listings {
	margin-left: 0;
	margin-right: 0;

	&__wrapper {
		padding: 0 0 40px;
	}

	& > div {
		@media (max-width:420px) {
			width: 100%;
		}
	}
}

.castlePanel {
	width: 100%;
	margin: 30px 0 0;
	padding: 10px 10px 20px;
	background: #fff;
	border: 1px solid #c7c7c7;
	position: relative;
	text-align: center;

	&:before {
		content: "";
		width: 15px;
		height: 50%;
		background: rgba(0,0,0,0.5);
		box-shadow: 0 0 5px #6e6e6e;
		position: absolute;
		top: 8px;
		left: -7px;
		transform-origin: center top;
		transform: rotate(-3deg);
		filter: blur(3px);
		transition: all 0.45s ease-out;
		z-index: -1;
	}

	&:after {
		.StickToEdges;
		background: #fff;
		z-index: -1;
	}

	&:hover {
		&:before {
			opacity: 0;
			left: 0px;
			top: 0px;
			transform: rotate(0deg);
			box-shadow: 0 0 0 #6e6e6e;
			filter: blur(0px);
		}
	}

	.SingleImagePanel {
		height: auto;
	}

	.castleTitlePanel {
		margin: 10px 0 0;
		padding: 0 5%;
		display: flex;
		justify-content: center;
		align-items: center;

		a {
			color: #303030;
			.PTSansNarrow;
			font-size: 1.4rem;
		}
	}

	.castlePriceDefault, .castlePriceDiscounted {
		padding: 0;
		margin-top: 10px;
		.PTSansNarrowBold;
		color: #d10300;
		font-size: 1.4rem;

		span.oldprice {
			//font-size: 0.8rem;
		}

		span.newprice {
			//font-size: 1rem;
		}
	}

	span.oldprice, span.newprice {
	}

	.castleCheckBook {
		margin-top: 10px;
		height: auto;
		.PTSansNarrow;
		background: #d10300;
		padding: 8px 15px;
		display: inline-block;
		font-size: 1.3rem;
	}
}

.DetailsTitle h1, .DetailsTitle h2, .DetailsMainImage {
	background-color: @castleDetailsColor;
}

.BookButtons {
	text-align: center;

	.BookButton, .CheckButton {
		width: 180px;
		margin-left: auto;
		float: none;
		margin: 0 10px 10px;
	}
}
// tidy up
.footer {
	background: #000;
	padding: 20px 0 50px;
	color: #a1a1a1;

	@media @mobile {
		text-align: center;
	}

	&__flex-row-sm {
		@media @sm {
			display: flex;
			align-items: center;
		}
	}

	&__text-center-sm {
		@media @sm {
			text-align: center;
		}
	}

	&__text-right-sm {
		@media @sm {
			text-align: right;
		}
	}

	&__links {
		text-align: center;
	}

	&__link {
		margin: 0 5px;
		color: #a1a1a1;

		&:hover {
			color: #a1a1a1;
		}
	}

	#BCNLink {
		color: #a1a1a1;

		&:hover {
			color: #a1a1a1;
		}
	}
}
/*#region Animations*/

/*#endregion Animations*/
